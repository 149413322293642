import React from 'react';

import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import withApiHooks from '../util/api-hooks';
import { committeeDispatch } from '../modules/committees';

export class TestRunDialog extends React.Component {
  componentDidMount() {
    this.props.clearResponse();
  }

  render() {
    const { committee } = this.props;
    const runTest = () => {
      this.props.clearResponse();
      this.props.beginAction(committee.alias, committee.members[0]);
    };

    return (
      <>
        <DialogTitle>
          Run test for '{committee.name}'
        </DialogTitle>
        <DialogContent>
          {!this.props.loading && this.props.response && (
            <div>Budget remaining: `{this.props.response}`</div>
          )}
          {this.props.loading && 'Running command...'}
        </DialogContent>
        <DialogActions>
          <Button
            id='testRunCancelBtn'
            onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button
            id='testRunSubmitBtn'
            onClick={runTest}>
            Test
          </Button>
        </DialogActions>
      </>
    );
  }
}

const opts = {
  apiSelector: state => state.committees.budget,
  apiActions: committeeDispatch.budget,
  mapStateToProps: state => ({ response: state.committees.budget.response }),
  mapDispatchToProps: dispatch => ({ clearResponse: () => dispatch(committeeDispatch.budget.clearResponse()) }),
  closeOnSuccess: false
};

export default withApiHooks(opts, TestRunDialog);