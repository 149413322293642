import React from 'react';
import { Typography } from '@material-ui/core';
import { Flex, Box } from '@rebass/grid';

import UserSelect from './UserSelect';
import { FormInput, FormContainer } from '../styled/committee-form.styled';

export default class CommitteeForm extends React.Component {
  static defaultProps = {
    members: []
  };

  state = {
    committee: this.props.committee || {
      name: '',
      alias: '',
      sheetsConfig: {
        id: '',
        cell: '',
        worksheet: ''
      },
      members: []
    },
    error: null
  };

  submit = () => this.submitRef.click();

  onSubmit = event => {
    const committee = {...this.state.committee};
    committee.members = committee.members.map(({ value }) => value);
    this.props.onSubmit(committee);
    event.preventDefault();
  };

  onFormValueChange = propName => event => {
    let committee = {...this.state.committee};
    const propArray = propName.split('.');
    
    let innerObj = committee;
    for (let i = 0; i < propArray.length - 1; i++) {
      const prop = propArray[i];
      innerObj[prop] = innerObj[prop] || {};
      innerObj = innerObj[prop];
    }

    innerObj[propArray[propArray.length - 1]] = event.target ? event.target.value : event;

    this.setState({ committee });
  };

  render() {
    const { committee } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <FormContainer flexDirection='column'>
          <FormInput
            label='Name'
            value={committee.name}
            onChange={this.onFormValueChange('name')}
            required
          />
          <FormInput
            label='Alias'
            value={committee.alias}
            onChange={this.onFormValueChange('alias')}
            required
          />
          <UserSelect
            label='Members'
            value={committee.members}
            options={this.props.slackUsers}
            onChange={this.onFormValueChange('members')}
            required
          />
          <Flex flexDirection='column'>
            <Box pb={3}>
              <Typography variant='body1'>Google Sheets Config</Typography>
            </Box>
            <FormInput
              label='ID'
              value={committee.sheetsConfig.id}
              onChange={this.onFormValueChange('sheetsConfig.id')}
              required
            />
            <Flex>
              <Box mr={3}>
                <FormInput
                  label='Worksheet'
                  value={committee.sheetsConfig.worksheet}
                  onChange={this.onFormValueChange('sheetsConfig.worksheet')}
                  required
                />
              </Box>
              <Box ml={3}>
                <FormInput
                  label='Cell'
                  value={committee.sheetsConfig.cell}
                  onChange={this.onFormValueChange('sheetsConfig.cell')}
                  required
                />
              </Box>
            </Flex>
          </Flex>
        </FormContainer>
        <button type='submit' ref={el => this.submitRef = el} hidden/>
      </form>
    );
  }
}