import React, { Component } from 'react';
import { connect } from 'react-redux';

import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/auth';

import PrivateRoute from './components/PrivateRoute';
import SignInScreen from './components/SignInScreen';
import Portal from './components/Portal';
import ModalRoot from './containers/ModalRoot';
import BannerRoot from './containers/BannerRoot';

import { notifyAuthState } from './modules/auth';

class App extends Component {
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(auth => (
      this.props.notifyAuthState(!!auth)
    ));
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (
      <>
        <BannerRoot />
        <Router>
          <div>
            <PrivateRoute path='/' exact component={Portal} />
            <Route path='/login' exact component={SignInScreen} />
          </div>
        </Router>
        <ModalRoot />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyAuthState: authState => dispatch(notifyAuthState(authState))
  };
};

export default connect(undefined, mapDispatchToProps)(App);
