import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';

import { getSortedSlackUsers } from '../selectors';
import withApiHooks from '../util/api-hooks';

import CommitteeForm from '../components/CommitteeForm';
import { committeeDispatch } from '../modules/committees';

export class CommitteeDialog extends React.Component {
  triggerSubmit = () => this.formRef.submit();

  render() {
    const onSubmit = this.props.editMode
      ? committee => this.props.beginAction(this.props.committee.guid, committee)
      : this.props.beginAction;

    return (
      <>
        <DialogTitle>
          {!this.props.editMode && 'Create committee'}
          {this.props.editMode && `Edit ${this.props.committee.name}`}
        </DialogTitle>
        <DialogContent>
          <CommitteeForm
            ref={el => this.formRef = el}
            committee={this.props.committee}
            onSubmit={onSubmit}
            slackUsers={this.props.slackUsers}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color='primary'>
            Cancel
          </Button>
          <Button
            id='submitButton'
            onClick={this.triggerSubmit}
            color='primary'
            disabled={this.props.loading}
          >
            {!this.props.editMode && 'Create'}
            {this.props.editMode && 'Update'}
          </Button>
        </DialogActions>
      </>
    );
  }
}

CommitteeDialog.propTypes = {
  editMode: PropTypes.bool
};

CommitteeDialog.defaultProps = {
  editMode: false
};

const opts = {
  apiSelector: (state, ownProps) => state.committees[ownProps.editMode ? 'put' : 'post'],
  apiActions: ({ editMode }) => committeeDispatch[editMode ? 'put' : 'post'],
  mapStateToProps: state => ({ slackUsers: getSortedSlackUsers(state) })
};

export default withApiHooks(opts, CommitteeDialog);