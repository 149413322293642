import React from 'react';
import styled from 'styled-components';

import { Icon } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Close from '@material-ui/icons/Close';
import { Flex, Box } from '@rebass/grid';

import { Grow } from './common.styled';

export const BannerTitle = styled(Box)`
  font-size: 1rem;
  font-weight: 700;
`;

export const BannerBody = styled(Box)`
  font-size: 0.825rem;
`;

const applyTheme = variant => {
  switch (variant) {
    case 'error':
      return {
       base: `
          background-color: #db2902;
       `,
      };
    case 'info':
      return {
        base: `
          background-color: #192cff;
        `
      };
    default: return {};
  }
};

export const BannerIcon = styled(Flex).attrs(props => {
  let child;
  switch (props.variant) {
    case 'error':
      child = <ErrorOutline />;
      break;
    case 'info':
      child = <InfoOutline />;
      break;
    default: break;
  }

  const children = child ? <Icon>{child}</Icon> : null;

  return {
    children,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  };
})`
  ${props => applyTheme(props.variant).icon}
  color: white;
  padding: 10px;
`;

export const BannerBase = styled(Flex).attrs({
  flexDirection: 'row'
})`
  ${props => !props.open && 'display: none;'}
  ${props => applyTheme(props.variant).base}
  width: 100%;
  height: 65px;
  color: white;
`;

export const BannerContent = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center'
})``;

const PointerIcon = styled(Icon)`
  cursor: pointer;
  margin: 5px;
`;

export const BannerClose = props => (
  <>
    <Grow key={1} />
    <Flex key={2}
      flexDirection='column'
      justifyContent='center'
      mr={3}
    >
      <PointerIcon {...props}>
        <Close />
      </PointerIcon>
    </Flex>
  </>
);