import createApiReducer from './api-reducers';

export const SLACK_API = {
  LIST_USERS: {
    BEGIN: 'portal/slack/LIST_USERS_BEGIN',
    SUCCESS: 'portal/slack/LIST_USERS_SUCCESS'
  }
};

export default createApiReducer({
  actions: {
    begin: SLACK_API.LIST_USERS.BEGIN,
    success: SLACK_API.LIST_USERS.SUCCESS
  },
  handlers: {
    onSuccess: action => ({ users: action.payload.users })
  },
  initialState: { users: [] }
});

export const slackDispatch = {
  listUsers: {
    begin: () => ({ type: SLACK_API.LIST_USERS.BEGIN }),
    success: users => ({ type: SLACK_API.LIST_USERS.SUCCESS, payload: { users } })
  }
};