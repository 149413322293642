import { createSelector } from 'reselect';

const getSlackUsers = state => state.slack.users;

export const getSortedSlackUsers = createSelector(
  [getSlackUsers],
  users => {
    return users.map(member => ({
      label: member.realName || member.name,
      value: member.id
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1);
  }
)