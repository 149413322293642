import { connect } from 'react-redux';

const initialState = {
  isAuthenticated: null
};

export const NOTIFY_AUTH_STATE = 'portal/auth/NOTIFY_AUTH_STATE';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NOTIFY_AUTH_STATE: {
      return {...state, isAuthenticated: action.authState };
    }
    default: return state;
  }
}

export function notifyAuthState(authState) {
  return { type: NOTIFY_AUTH_STATE, authState };
}

/**
 * Higher-order component that passes the wrapped component a 'isAuthenticated'
 * prop from the Redux auth store.
 * 
 * props.isAuthenticated is a boolean.
 * 
 * @param {React Component} Component component to receive isAuthenticated prop
 */
export function withAuthentication(Component) {
  const mapStateToProps = ({ auth }) => ({
    isAuthenticated: auth.isAuthenticated
  });

  return connect(mapStateToProps)(Component);
}