const initialState = {
  modalType: null,
  modalProps: {}
};

export const SHOW_MODAL = 'portal/modal/SHOW_MODAL';
export const HIDE_MODAL = 'portal/modal/HIDE_MODAL';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    }
    case HIDE_MODAL: {
      return initialState;
    }
    default: return state;
  }
}

export function showModal(modalType, modalProps = {}) {
  return { type: SHOW_MODAL, modalType, modalProps };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}