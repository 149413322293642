import { capitalizeFirstLetter } from '../util';

export default function createReducer({ actions: apiActions, handlers = {}, initialState = {} }) {
  const _state = {
    loading: false,
    error: null,
    success: false,
    ...initialState
  };

  const defaultActions = {
    begin: 'portal/API_REDUCER/DEFAULT_BEGIN',
    success: 'portal/API_REDUCER/DEFAULT_SUCCESS',
    error: 'portal/API_REDUCER/DEFAULT_ERROR'
  };

  return (state = _state, action = {}) => {
    const actions = {...defaultActions, ...apiActions};
    switch (action.type) {
      case actions.begin: {
        const handlerResult = handlers.onBegin ? handlers.onBegin(action) : {};
        return {
          ...state,
          loading: true,
          error: null,
          success: false,
          ...handlerResult
        };
      }
      case actions.success: {
        const handlerResult = handlers.onSuccess ? handlers.onSuccess(action) : {};
        return {
          ...state,
          loading: false,
          success: true,
          ...handlerResult
        };
      }
      case actions.error: {
        const handlerResult = handlers.onError ? handlers.onError(action) : {};
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload.error,
          ...handlerResult
        };
      }
      case actions.acknowledgeSuccess: {
        const handlerResult = handlers.onAcknowledgeSuccess ? handlers.onAcknowledgeSuccess(action) : {};
        return {
          ...state,
          success: false,
          ...handlerResult
        };
      }
      default: {
        const actionTypes = Object.keys(actions);
        for (let key of actionTypes) {
          if (action.type === actions[key]) {
            const handlerName = `on${capitalizeFirstLetter(key)}`;
            const handler = handlers[handlerName];
            if (!handler) return state;

            return handler.length === 2 ? {
              ...state,
              ...handler(state, action)
            } : {
              ...state,
              ...handler(action)
            };
          }
        }

        return state;
      }
    }
  };
}