import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import firebase from 'firebase/app';
import 'firebase/functions';

import portalApp from './modules';
import rootSaga from './sagas';

const config = {
  apiKey: 'AIzaSyBTd8HMTdOfWU9AhT2Kgy-LwZdTiNiNmKg',
  authDomain: 'management-portal-d7e40.firebaseapp.com',
  projectId: 'management-portal-d7e40'
};

firebase.initializeApp(config);

// Set the Firebase functions url to point to local
// emulated server rather than the actual one to avoid CORS issues
if (process.env.NODE_ENV === 'development') {
  firebase.functions()._url = function (name) {
    return `${process.env.REACT_APP_API_URL}/${name}`
  }
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  portalApp,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
