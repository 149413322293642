import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Flex } from '@rebass/grid';

import { media, constrained } from './index';

const Input = props => <TextField {...props} InputLabelProps={{shrink: true}} />

export const FormInput = styled(Input)`
  ${props => !props.noPadding && 'padding-bottom: 15px!important;'}
`;

export const FormContainer = styled(Flex)`
  ${constrained.width(300/16)}

  ${media.phone`${constrained.width(220/16)}`}
  ${media.smallPhone`${constrained.width(170/16)}`}
`;
