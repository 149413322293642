import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import AddButton from '@material-ui/icons/Add';

import { showModal } from '../modules/modal';

const CreateButton = ({ loading, dispatch }) => (
  <IconButton
    onClick={
      () => dispatch(showModal('COMMITTEE_DIALOG', { editMode: false }))
    }
    disabled={loading}
  >
    <AddButton />
  </IconButton>
);

export default connect(state => state.committees.fetch)(CreateButton);