import { combineReducers } from 'redux';
import auth from './auth';
import committees from './committees';
import modal from './modal';
import slack from './slack';
import banner from './banner';

const portalApp = combineReducers({
  auth,
  committees,
  modal,
  slack,
  banner
});

export default portalApp;