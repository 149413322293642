import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Box } from '@rebass/grid';

import CenterFlex from './CenterFlex';
import CommitteePanels from '../containers/CommitteePanels';
import { StyledCard, HeaderTitle } from '../styled/portal.styled';
import { Grow } from '../styled/common.styled';

import {
  Button,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

import CreateButton from '../containers/CreateButton';

export default class Portal extends React.Component {
  render() {
    return (
      <CenterFlex
        flexStyle={{
          flexDirection: 'column',
          justifyContent: 'normal',
          pt: 5
        }}
      >
        <StyledCard>
          <CardHeader
            disableTypography
            style={{paddingBottom: '0px'}}
            title={<HeaderTitle>Committees</HeaderTitle>}
            action={
              <Box mr={2}>
                <CreateButton />
              </Box>
            }
          />
          <CardContent>
            <CommitteePanels />
          </CardContent>
          <CardActions>
            <Button
              id='signOutBtn'
              variant='contained'
              color='secondary'
              onClick={() => firebase.auth().signOut()}>
              Sign out
            </Button>
            <Grow />
            <IconButton component='a' href={process.env.REACT_APP_HELP_LINK} target='_blank'>
              <HelpOutline />
            </IconButton>
          </CardActions>
        </StyledCard>
      </CenterFlex>
    );
  }
}