import React from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import {
  Paper,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  Button
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons'
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { Redirect } from 'react-router-dom';

import CenterFlex from './CenterFlex';
import { withAuthentication } from '../modules/auth';

const PaddedPaper = styled(Paper)`
  padding: 10px;
`;

const FixedWidthControl = styled(FormControl)`
  min-width: ${props => props.width || 192}px!important;
  max-width: ${props => props.width || 192}px!important;
`;

export class SignInScreen extends React.Component {
  state = {
    password: '',
    error: null
  };

  onPasswordValueChange = event => this.setState({ password: event.target.value });

  login = event => {
    // Use a fixed email address in order to only require knowledge of a password
    const email = 'zetapsiuiuc@gmail.com';
    firebase.auth()
      .signInWithEmailAndPassword(email, this.state.password)
      .then(() => this.setState({ error: null }))
      .catch(err => {
        this.setState({
          error: {
            code: err.code,
            message: err.code === 'auth/wrong-password'
              ? 'Incorrect password'
              : 'An unknown error occurred. Please try again later.'
          }
        });
        console.log(err);
      });
    event.preventDefault();
  };

  render() {
    const { from = '/' } = this.props.location.state || {};

    // Resolve authentication status before rendering anything
    if (this.props.isAuthenticated == null) {
      return null;
    // Redirect to previous route once user is authenticated
    } else if (this.props.isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <CenterFlex>
        <PaddedPaper>
          <form onSubmit={this.login}>
            <FixedWidthControl>
              <InputLabel htmlFor='password' shrink>Password</InputLabel>
              <Input
                error={!!this.state.error}
                required
                id='password'
                type='password'
                value={this.state.password}
                onChange={this.onPasswordValueChange}
                endAdornment={
                  this.state.password !== '' && (
                    <InputAdornment position='end'>
                      <IconButton type='submit'>
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              {this.state.error && this.state.error.message && (
                <FormHelperText error>
                  {this.state.error.message}
                </FormHelperText>
              )}
            </FixedWidthControl>
          </form>
        </PaddedPaper>
        <Box my={2}>
          <Button>Forgot Password?</Button>
        </Box>
      </CenterFlex> 
    );
  }
}

export default withAuthentication(SignInScreen);