import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Flex } from '@rebass/grid';

import {
  Capitalized,
  Label,
  AliasLabel,
  SheetDetails,
  PanelActions,
} from '../styled/panel-list.styled';
import { Grow } from '../styled/common.styled';

import PanelActionMenu from '../containers/PanelActionMenu';

export default class PanelList extends React.Component {
  state = {
    expanded: null
  };

  componentDidMount() {
    this.props.fetchCommittees();
    this.props.listSlackUsers();
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  renderCommitteePanel = (committee, idx) => {
    const details = ['worksheet', 'cell', 'id'].map(key => ({
      name: key === 'id' ? 'ID' : key,
      value: committee.sheetsConfig[key]
    }));

    let { members } = committee;
    members = members.map(id => {
      for (let user of this.props.users) {
        if (user.id === id) {
          return user.realName || user.name;
        }
      }
      return null;
    });

    return (
      <ExpansionPanel
        key={committee.alias}
        expanded={this.state.expanded === `panel${idx}`}
        onChange={this.handleChange(`panel${idx}`)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Capitalized>{committee.name}</Capitalized>
          <Grow />
          <AliasLabel>{committee.alias}</AliasLabel>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{paddingBottom: '2px'}}>
          <SheetDetails
            flexDirection='row'
            flex={1}
          >
            {
              details.map(({ name, value }, i) => (
                <Flex
                  key={i}
                  flexDirection='column'
                  mr={3}
                >
                  <Label>
                    {`${name}:`}
                  </Label>
                  <Typography variant='body2'>{value}</Typography>
                </Flex>
              ))
            }
          </SheetDetails>
        </ExpansionPanelDetails>
        <PanelActions>
          <PanelActionMenu committee={committee}/>
          <Grow />
          <Button
            size='small'
            color='primary'
            variant='contained'
            onClick={this.props.viewMembers(committee.name, members)}
          >
            View members
          </Button>
        </PanelActions>
      </ExpansionPanel>
    );
  };

  render() {
    return (
      <>
        {
          !this.props.loading && ! this.props.error && (
            this.props.committees.map(this.renderCommitteePanel)
          )
        }
        {
          this.props.loading && (
            <Flex>
              <div>Fetching committees...</div>
            </Flex>
          )
        }
      </>
    );
  }
}