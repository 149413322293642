import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import { StyledInput, PaddedSelect } from '../styled/user-select.styled';

function inputComponent({ inputRef, ...props }) {
  return <StyledInput ref={inputRef} style={{display: 'flex'}} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const components = {
  Control,
  Option
};

const UserSelect = ({ label, required, ...rest }) => (
  <PaddedSelect
    textFieldProps={{
      label,
      InputLabelProps: { shrink: true },
      required
    }}
    isMulti
    components={components}
    {...rest}
  />
);

export default UserSelect;