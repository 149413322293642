import styled from 'styled-components';

import {
  IconButton
} from '@material-ui/core';

export const Grow = styled.div`
  flex-grow: 1
`;

export const SquareIconButton = styled(IconButton)`
  padding: 5px!important;
  border-radius: 0!important;
`;