import createApiReducer from './api-reducers';
import { showError } from './banner';

export const BUDGET_API = {
  BEGIN: 'portal/committees/budget/FETCH_BEGIN',
  SUCCESS: 'portal/committees/budget/FETCH_SUCCESS',
  ERROR: 'portal/committees/budget/FETCH_ERROR',
  ACKNOWLEDGE_SUCCESS: 'portal/committees/budget/ACKNOWLEDGE_FETCH_SUCCESS',
  CLEAR_RESPONSE: 'portal/committess/budget/RESET'
};

export const budgetReducer = createApiReducer({
  actions: {
    begin: BUDGET_API.BEGIN,
    success: BUDGET_API.SUCCESS,
    error: BUDGET_API.ERROR,
    acknowledgeSuccess: BUDGET_API.ACKNOWLEDGE_SUCCESS,
    clearResponse: BUDGET_API.CLEAR_RESPONSE
  },
  initialState: { response: null },
  handlers: {
    onSuccess: action => ({ response: action.payload }),
    onClearResponse: () => ({ response: null })
  }
});

export const budgetDispatch = {
  begin: (alias, userId) => ({ type: BUDGET_API.BEGIN, payload: { alias, userId } }),
  success: payload => ({ type: BUDGET_API.SUCCESS, payload }),
  error: error => ({ type: BUDGET_API.ERROR, payload: { error } }),
  acknowledgeSuccess: () => ({ type: BUDGET_API.ACKNOWLEDGE_SUCCESS }),
  clearResponse: () => ({ type: BUDGET_API.CLEAR_RESPONSE }),
  showErrorBanner: code => {
    let bannerProps = {};

    switch (code) {
      case 400: {
        bannerProps = {
          title: 'Could not complete the request.',
          body: 'Ensure that the provided worksheet and cell names are correct.'
        };
        break;
      }
      case 403: {
        bannerProps = {
          title: 'Insufficient permissions.',
          body: 'Ensure that the provided spreadsheet is shared with the app account. See the help section for details.'
        };
        break;
      }
      case 404: {
        bannerProps = {
          title: 'Could not find a spreadsheet with the given ID'
        };
        break;
      }
      default: break;
    }

    return showError(bannerProps);
  }
};