import { call, put, takeEvery } from 'redux-saga/effects';

import {
  COMMITTEES_API,
  committeeDispatch
} from '../modules/committees';

import {
  SLACK_API,
  slackDispatch
} from '../modules/slack';

import { hideBanner, showError } from '../modules/banner';

import committees from '../api/committees';
import * as slack from '../api/slack-client';
import { replaceKey } from '../util';

export default function* rootSaga() {
  yield takeEvery(COMMITTEES_API.FETCH.BEGIN, fetchCommittees);
  yield takeEvery(COMMITTEES_API.POST.BEGIN, postCommittee);
  yield takeEvery(COMMITTEES_API.PUT.BEGIN, putCommittee);
  yield takeEvery(COMMITTEES_API.DELETE.BEGIN, deleteCommittee);
  yield takeEvery(COMMITTEES_API.BUDGET.BEGIN, runTestCommand);
  yield takeEvery(SLACK_API.LIST_USERS.BEGIN, listSlackUsers);
}

export function* fetchCommittees() {
  const response = yield call(committees.get);
  if (response.data) {
    let { committees } = response.data;
    committees = committees.map(c => replaceKey(c, 'sheetsConfig', 'sheet_data'));
    yield put(committeeDispatch.fetch.success(committees));
    yield put(hideBanner());
  }
}

export function* postCommittee(action) {
  const { committee } = action.payload;
  try {
    yield call(committees.post, committee);
    yield put(committeeDispatch.post.success(committee));
    yield put(hideBanner());
  } catch (err) {
    yield put(committeeDispatch.post.error(err.details));
    const { code } = err.details;
    if (code === 400) {
      yield put(showError({ title: err.details.message }));
    }
  }
}

export function* putCommittee(action) {
  const { guid, committee } = action.payload;
  const response = yield call(committees.put, guid, committee);
  if (response.data) {
    let { committee: modifiedCommittee } = response.data;
    modifiedCommittee = replaceKey(modifiedCommittee, 'sheetsConfig', 'sheet_data');
    yield put(committeeDispatch.put.success(guid, modifiedCommittee));
    yield put(hideBanner());
  }
}

export function* deleteCommittee(action) {
  const { guid } = action.payload;
  yield call(committees.delete, guid);
  yield put(committeeDispatch.delete.success(guid));
  yield put(hideBanner());
}

export function* runTestCommand(action) {
  const { alias, userId } = action.payload;
  try {
    const response = yield call(committees.budget.get, { alias, userId });
    const { ok, budget } = response.data;
    if (ok) {
      yield put(committeeDispatch.budget.success(budget));
      yield put(hideBanner());
    }
  } catch (err) {
    yield put(committeeDispatch.budget.error(err.details));
    const { code } = err.details;
    if (code === 400 || code === 403 || code === 404) {
      yield put(committeeDispatch.budget.showErrorBanner(code));
    }
  }
}

export function* listSlackUsers() {
  const users = yield call(slack.listUsers);
  yield put(slackDispatch.listUsers.success(users));
}