/**
 * Wrapper class for the committees budget hook.
 * 
 * The intended use for this function is to allow admins to
 * verify that they have configured budget app correctly.
 * 
 * This is not intended to be used as a full-fledged budget query system.
 */
import callable from './firebase-callable';

const CALLABLE_NAME = 'committeeBudgetHook';
const budgetHook = callable(CALLABLE_NAME);

const api = {
  // If admin has not provided the appengine acct
  // with access to the sheet, returns a 403 error.
  get({ alias, userId }) {
    return budgetHook.get({ alias, userId });
  }
};

export default api;