import { combineReducers } from 'redux';

import createApiReducer from './api-reducers';
import { budgetDispatch, budgetReducer, BUDGET_API } from './committee-budget';

export const COMMITTEES_API = {
  FETCH: {
    BEGIN: 'portal/committees/FETCH_COMMITTEES_BEGIN',
    SUCCESS: 'portal/committees/FETCH_COMMITTEES_SUCCESS',
    ACKNOWLEDGE_SUCCESS: 'portal/committees/FETCH_COMMITTEES_ACKNOWLEDGE_SUCCESS'
  },
  POST: {
    BEGIN: 'portal/committees/POST_COMMITTEE_BEGIN',
    SUCCESS: 'portal/committees/POST_COMMITTEE_SUCCESS',
    ERROR: 'portal/committees/POST_COMMITTEE_ERROR',
    ACKNOWLEDGE_SUCCESS: 'portal/committees/POST_COMMITTEE_ACKNOWLEDGE_SUCCESS'
  },
  PUT: {
    BEGIN: 'portal/committees/PUT_COMMITTEE_BEGIN',
    SUCCESS: 'portal/committees/PUT_COMMITTEE_SUCCESS',
    ACKNOWLEDGE_SUCCESS: 'portal/committees/PUT_COMMITTEE_ACKNOWLEDGE_SUCCESS'
  },
  DELETE: {
    BEGIN: 'portal/committees/DELETE_COMMITTEE_BEGIN',
    SUCCESS: 'portal/committees/DELETE_COMMITTEE_SUCCESS',
    ACKNOWLEDGE_SUCCESS: 'portal/committees/DELETE_COMMITTEE_ACKNOWLEDGE_SUCCESS'
  },
  BUDGET: BUDGET_API
};

const sortByName = (cA, cB) => cA.name > cB.name ? 1 : -1;

export default combineReducers({
  fetch: createApiReducer({
    actions: {
      begin: COMMITTEES_API.FETCH.BEGIN,
      success: COMMITTEES_API.FETCH.SUCCESS,
      acknowledgeSuccess: COMMITTEES_API.FETCH.ACKNOWLEDGE_SUCCESS,
      postSuccess: COMMITTEES_API.POST.SUCCESS,
      putSuccess: COMMITTEES_API.PUT.SUCCESS,
      deleteSuccess: COMMITTEES_API.DELETE.SUCCESS
    },
    handlers: {
      onSuccess: action => ({ items: action.payload.committees.sort(sortByName) }),
      onPostSuccess: (state, action) => ({
        items: [...state.items, action.payload.committee].sort(sortByName)
      }),
      onPutSuccess: (state, action) => {
        const { guid, committee } = action.payload;
        return {
          items: state.items.map(item => guid !== item.guid ? item : committee).sort(sortByName)
        };
      },
      onDeleteSuccess: (state, action) => ({
        items: state.items.filter(item => item.guid !== action.payload.guid)
      })
    },
    initialState: { items: [] }
  }),
  post: createApiReducer({
    actions: {
      begin: COMMITTEES_API.POST.BEGIN,
      success: COMMITTEES_API.POST.SUCCESS,
      error: COMMITTEES_API.POST.ERROR,
      acknowledgeSuccess: COMMITTEES_API.POST.ACKNOWLEDGE_SUCCESS
    }
  }),
  put: createApiReducer({
    actions: {
      begin: COMMITTEES_API.PUT.BEGIN,
      success: COMMITTEES_API.PUT.SUCCESS,
      acknowledgeSuccess: COMMITTEES_API.PUT.ACKNOWLEDGE_SUCCESS
    }
  }),
  delete: createApiReducer({
    actions: {
      begin: COMMITTEES_API.DELETE.BEGIN,
      success: COMMITTEES_API.DELETE.SUCCESS,
      acknowledgeSuccess: COMMITTEES_API.DELETE.ACKNOWLEDGE_SUCCESS
    }
  }),
  budget: budgetReducer
});

export const committeeDispatch = {
  post: {
    begin: committee => ({ type: COMMITTEES_API.POST.BEGIN, payload: { committee } }),
    success: committee => ({ type: COMMITTEES_API.POST.SUCCESS, payload: { committee } }),
    error: error => ({ type: COMMITTEES_API.POST.ERROR, payload: { error }}),
    acknowledgeSuccess: () => ({ type: COMMITTEES_API.POST.ACKNOWLEDGE_SUCCESS })
  },
  fetch: {
    begin: () => ({ type: COMMITTEES_API.FETCH.BEGIN }),
    success: committees => ({ type: COMMITTEES_API.FETCH.SUCCESS, payload: { committees } }),
    acknowledgeSuccess: () => ({ type: COMMITTEES_API.FETCH.ACKNOWLEDGE_SUCCESS })
  },
  put: {
    begin: (guid, committee) => ({ type: COMMITTEES_API.PUT.BEGIN, payload: { guid, committee }}),
    success: (guid, committee) => ({ type: COMMITTEES_API.PUT.SUCCESS, payload: { guid, committee }}),
    acknowledgeSuccess: () => ({ type: COMMITTEES_API.PUT.ACKNOWLEDGE_SUCCESS })
  },
  delete: {
    begin: guid => ({ type: COMMITTEES_API.DELETE.BEGIN, payload: { guid }}),
    success: guid => ({ type: COMMITTEES_API.DELETE.SUCCESS, payload: { guid }}),
    acknowledgeSuccess: () => ({ type: COMMITTEES_API.DELETE.ACKNOWLEDGE_SUCCESS })
  },
  budget: budgetDispatch
};