import styled from 'styled-components';

import Select from 'react-select';

export const StyledInput = styled.div`
  padding: 0px!important;
`;

export const PaddedSelect = styled(Select)`
  padding-bottom: 15px;
`;