import firebase from 'firebase/app';
import 'firebase/functions';

const methods = ['get', 'put', 'post', 'delete'];

/**
 * Wrap Firebase HTTPS Callable functions with a REST-like API.
 * Wrapped callables should expect to receive as data an object containing
 * two keys:
 *  - method: a REST verb (currently, one of get, put, post, delete)
 *  - body: a (serializable) object that is forwarded to the main function body
 */
export default function callable(fnName) {
  return methods.reduce((acc, method) => {
    acc[method] = data => {
      const callableInst = firebase.functions().httpsCallable(fnName);
      return callableInst({ body: data, method });
    };
    return acc;
  }, {});
};