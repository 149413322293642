import React from 'react';
import { connect } from 'react-redux';

import { Menu } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { SquareIconButton } from '../styled/common.styled';
import { StyledMenuItem } from '../styled/panel-action-menu.styled';

import { showModal } from '../modules/modal';

class PanelActionMenu extends React.Component {
  state = {
    anchorEl: null
  };

  menuActions = {
    test: this.props.onTestCommittee,
    edit: this.props.onEdit,
    delete: this.props.onDelete
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  handleMenuEvent = (eventType, ...args) => () => {
    const handler = this.menuActions[eventType] || (() => {});
    handler(...args);
    this.handleClose();
  };

  render() {
    const options = [
      { type: 'edit', label: 'Edit', args: [this.props.users] },
      { type: 'test', label: 'Test' },
      { type: 'delete', label: 'Delete' }
    ];

    return (
      <>
        <SquareIconButton onClick={this.handleClick}>
          <MoreHoriz />
        </SquareIconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {options.map(option => (
            <StyledMenuItem
              key={option.label}
              onClick={this.handleMenuEvent(option.type, ...(option.args || []))}
            >
              {option.label}
            </StyledMenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { committee } = ownProps;
  return {
    onTestCommittee: () => dispatch(showModal('TEST_RUN', { committee })),
    onEdit: users => {
      // Restructure members prop to match what react-select expects
      const committeeToEdit = {
        ...committee,
        members: committee.members.map(member => {
          for (let user of users) {
            if (user.id === member) {
              const name = user.realName || user.name;
              return { label: name, value: member };
            }
          }
          return { label: '', value: '' };
        })
      };

      dispatch(showModal('COMMITTEE_DIALOG', { editMode: true, committee: committeeToEdit }));
    },
    onDelete: () => dispatch(showModal('DELETE_COMMITTEE', { committee }))
  };
};

export default connect(state => state.slack, mapDispatchToProps)(PanelActionMenu);