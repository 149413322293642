import React from 'react';
import {
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Paper
} from '@material-ui/core';

const MemberListModal = ({ committeeName, members }) => (
  <>
    <DialogTitle>Members of {committeeName}</DialogTitle>
    <DialogContent>
      <Paper>
        <List dense>
          {
            members.map((member, idx) => (
              <ListItem key={idx}>
                <ListItemText primary={member}/>
              </ListItem>
            ))
          }
        </List>
      </Paper>
    </DialogContent>
  </>
)

export default MemberListModal;