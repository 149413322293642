import { connect } from 'react-redux';

import PanelList from '../components/PanelList';

import { committeeDispatch } from '../modules/committees';
import { slackDispatch } from '../modules/slack';
import { showModal } from '../modules/modal';

const mapStateToProps = state => {
  return {
    loading: state.committees.fetch.loading,
    committees: state.committees.fetch.items,
    users: state.slack.users 
    // committees: [{
    //   name: 'Foo',
    //   members: ['U250CNF', 'U250CCA23'],
    //   sheetsConfig: {
    //     id: '10kA5EVqxM8klNSi15Q7DffdSHQsIFVWrdqYty4Axp6c',
    //     worksheet: 'Overview',
    //     cell: 'A4'
    //   },
    //   alias: 'foo'
    // }, {
    //   name: 'New Member Ed',
    //   alias: 'nme',
    //   members: ['U32CB34F', 'UGD32403N', 'U1413CAI'],
    //   sheetsConfig: {
    //     id: '1gbah201ja',
    //     worksheet: 'Budget',
    //     cell: 'D5'
    //   },
    // }]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCommittees: () => dispatch(committeeDispatch.fetch.begin()),
    listSlackUsers: () => dispatch(slackDispatch.listUsers.begin()),
    viewMembers: (committeeName, members) => () => dispatch(showModal('MEMBER_LIST', { members, committeeName }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelList);