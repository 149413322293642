import React from 'react';
import { connect } from 'react-redux';

export default function withApiHooks(opts, Component) {
  const noop = (() => {});
  const {
    apiSelector, // selector fn that takes the Redux state and returns the API state
    apiActions,
    mapStateToProps: stateMapFn = noop,
    mapDispatchToProps: dispatchMapFn = noop,
    closeOnSuccess = true
  } = opts;

  const hoc = class extends React.Component {
    componentDidUpdate(prevProps) {
      if (!prevProps.didSucceed && this.props.didSucceed) {
        this.props.acknowledgeSuccess();
        if (closeOnSuccess) {
          this.props.onClose();
        }
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };

  const mapStateToProps = (state, ownProps) => {
    const { success, loading } = apiSelector(state, ownProps);
    return {
      didSucceed: success,
      loading,
      ...stateMapFn(state, ownProps)
    };
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    let actions = apiActions;
    if (typeof apiActions === 'function') {
      actions = apiActions(ownProps);
    }

    return {
      beginAction: (...args) => dispatch(actions.begin(...args)),
      acknowledgeSuccess: () => dispatch(actions.acknowledgeSuccess()),
      ...dispatchMapFn(dispatch, ownProps)
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(hoc);
};