import React from 'react';
import { DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';

import withApiHooks from '../util/api-hooks';
import { committeeDispatch } from '../modules/committees';

class DeleteConfirmation extends React.Component {
  render() {
    const { committee } = this.props;
    return (
      <>
        <DialogTitle>
          Are you sure you want to delete '{committee.name}'?
        </DialogTitle>
        <DialogContent>
          This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button
            id='deleteCommitteeBtn'
            onClick={() => this.props.beginAction(committee.guid)}
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </>
    );
  }
};

const opts = {
  apiSelector: state => state.committees.delete,
  apiActions: committeeDispatch.delete
};

export default withApiHooks(opts, DeleteConfirmation);