import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { media, constrained } from './index';

export const StyledCard = styled(Card)`
  ${constrained.width(400/16)}
  overflow: hidden;

  ${media.phone`${constrained.width(336/16)}`}
  ${media.smallPhone`${constrained.width(272/16)}`}
`;

export const HeaderTitle = styled.span`
  margin-left: ${5/16}px;
  font-size: 1rem;
`;