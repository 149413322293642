import React from 'react';

import {
  BannerBase,
  BannerTitle,
  BannerIcon,
  BannerBody,
  BannerContent,
  BannerClose
} from '../styled/banner.styled';

const Banner = ({ open, variant, title, body, onClose }) => (
  <BannerBase open={open} variant={variant}>
    <BannerIcon variant={variant} />
    <BannerContent>
      <BannerTitle>{title}</BannerTitle>
      {body && <BannerBody>{body}</BannerBody>}
    </BannerContent>
    <BannerClose onClick={onClose} />
  </BannerBase>
);

export default Banner;