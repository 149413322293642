import React from 'react';
import { connect } from 'react-redux';
import Banner from '../components/Banner';
import { hideBanner } from '../modules/banner.js';

const BannerRoot = ({ bannerProps, ...rest }) => <Banner {...bannerProps} {...rest} />;

export default connect(
  state => state.banner,
  dispatch => ({
    onClose: () => dispatch(hideBanner())
  })
)(BannerRoot);