import styled from 'styled-components';
import { Typography, ExpansionPanelActions } from '@material-ui/core';
import { Flex } from '@rebass/grid';

export const Capitalized = styled(Typography)`
  text-transform: capitalize;
`;

export const Label = styled.span`
  font-size: ${12/16}rem;
  text-transform: capitalize;
  font-weight: 700;
`;

export const AliasLabel = styled.span`
  font-size: ${14/16}rem;
  color: #969696;
`;

export const SheetDetails = styled(Flex)`
  overflow-x: scroll;
  padding-bottom: 17px;
  box-sizing: content-box;
  height: 100%;
`;

export const PanelActions = styled(ExpansionPanelActions)`
  padding-top: 5px!important;
  padding-bottom: 10px!important;
`;