import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

const StyledFlex = styled(Flex)`
  min-height: ${props => (props.minHeight || 100)}vh;
`;

class CenterFlex extends React.Component {
  render() {
    return (
      <StyledFlex
        style={{backgroundColor: '#fafafa'}}
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        flexDirection='column'
        {...this.props.flexStyle}>
        {this.props.children}
      </StyledFlex>
    );
  }
}

export default CenterFlex;