import { css } from 'styled-components';

const sizes = {
  desktop: 992,
  phone: 450,
  smallPhone: 350
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {});

export const constrained = ['width', 'height'].reduce((acc, ty) => {
  acc[ty] = value => `
    min-${ty}: ${value}rem;
    max-${ty}: ${value}rem;
  `;
  return acc;
}, {});