import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import CommitteeDialog from './CommitteeDialog';
import MemberListModal from '../components/MemberListModal';
import DeleteConfirmation from './DeleteConfirmation';
import TestRunDialog from './TestRunDialog';

import { hideModal } from '../modules/modal';

const MODAL_COMPONENTS = {
  'COMMITTEE_DIALOG': CommitteeDialog,
  'MEMBER_LIST': MemberListModal,
  'DELETE_COMMITTEE': DeleteConfirmation,
  'TEST_RUN': TestRunDialog
};

const ModalRoot = ({ modalType, modalProps, dispatch }) => {
  if (!modalType) return null;

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return (
    <Dialog open onClose={() => dispatch(hideModal())}>
      <SpecificModal {...modalProps} onClose={() => dispatch(hideModal())} />
    </Dialog>
  );
};

export default connect(state => state.modal)(ModalRoot);