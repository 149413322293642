import callable from './firebase-callable';
import budget from './committee-budget';
import { replaceKey } from '../util';

const CALLABLE_NAME = 'committeeHook';
const committeesHook = callable(CALLABLE_NAME);

const api = {
  get(alias) {
    return committeesHook.get({ alias });
  },
  delete(guid) {
    return committeesHook.delete({ guid });
  },
  /**
   * Create a committee.
   * 
   * Committee object should have the following properties:
   *  - members: Array - an array of strings containing the Slack IDs of the members of the committee
   *  - name: string - Name of the committee, for reference to the admin
   *  - alias: string - Identifier used in the slack budget command to refer to the committee
   *  - sheetsConfig: Object - configuration data for the Google spreadsheet
   *    - cell: string - cell ID containing the budget value
   *    - worksheet: string - worksheet ID (Overview, Social, etc.)
   *    - id: string - id of the workbook. Obtained from the browser URL
   * 
   * @param {Committee} committee 
   */
  post(committee) {
    const { alias } = committee;
    // Rename sheetsConfig to sheets_data
    const committeeData = replaceKey(committee, 'sheet_data', 'sheetsConfig');
    return committeesHook.post({ alias, committee: committeeData });
  },
  put(guid, committee) {
    // TODO: validate keys?
    let committeeData = committee;
    if (committee.sheetsConfig) {
      committeeData = replaceKey(committee, 'sheet_data', 'sheetsConfig');
    }
    return committeesHook.put({ guid, committee: committeeData });
  },
  // Committee budget API
  budget
};

export default api;