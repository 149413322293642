const initialState = {
  open: false,
  bannerProps: {}
};

export const SHOW_BANNER = 'portal/banner/SHOW_BANNER';
export const HIDE_BANNER = 'portal/banner/HIDE_BANNER';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_BANNER: {
      return {
        ...state,
        open: true,
        bannerProps: action.bannerProps
      };
    }
    case HIDE_BANNER:
      return initialState;
    default: return state;
  }
}

export function showError({ title, body }) {
  return {
    type: SHOW_BANNER,
    bannerProps: {
      variant: 'error',
      title,
      body
    }
  };
}

export function hideBanner() {
  return { type: HIDE_BANNER };
}