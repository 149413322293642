import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { withAuthentication } from '../modules/auth';

/**
 * Auth-protected route. Redirects to login route if user is not
 * authenticated. Otherwise, it renders the provided component
 * 
 * @param {*} param0 
 */
function PrivateRoute({ component: Component, ...rest }) {
  const innerRoute = ({ isAuthenticated, location, ...props}) => {
    if (isAuthenticated == null) {
      return null;
    }
    
    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location }
        }}
      />
    )
  };

  // withAuthentication provides the route component with authentication
  // information from the Redux store
  return <Route {...rest} component={withAuthentication(innerRoute)} />;
}

export default PrivateRoute;